.App {
  /* text-align: center; */
  /* min-height: 100vh; */
  /* background-color: #D8DBE2; */

}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  /* height: 40vmin; */
  height: 4em;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  margin: 4px 0;
}

/* Login */
.login-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: row; 
  flex-wrap: wrap;
  justify-content: center; 
  max-width: 300px; 
  margin: 0 auto;
  /* box-shadow: 0 1px 5px black; */
  padding: 2.5%;
  border-radius: 2.5px;
  margin-top: 25px;
}

.inputStyle {
  display: block;
  margin: 10px 0;
  flex-basis: 100%;
  /* height: 40px; */
  padding: 10px 0;
}

/* Home */
.home-container {
  margin-top: 100px;
  text-align: center;
}

.home-icon {
  color: #424242;
  font-size: 55px;
  transition: all 0.2s ease-in;
}

/* .home-icon:hover {
  color: white;
  font-size: 65px;
} */

/* Blink */
@-webkit-keyframes blink {
  from { background-color: green; }
  to { background-color: inherit; }
}
@-moz-keyframes blink {
  from { background-color: green; }
  to { background-color: inherit; }
}
@-o-keyframes blink {
  from { background-color: green; }
  to { background-color: inherit; }
}
@keyframes blink {
  from { background-color: green; }
  to { background-color: inherit; }
}
.blink {
  -webkit-animation: blink 1s infinite; /* Safari 4+ */
  -moz-animation:    blink 1s infinite; /* Fx 5+ */
  -o-animation:      blink 1s infinite; /* Opera 12+ */
  animation:         blink 1s infinite; /* IE 10+ */
}




  .toms-select-component__menu {
    z-index: 99999999 !important;
  }

  .css-vj8t7z {
    border-radius: 0 !important;
  }